import './App.css';
import React from 'react';
import About from './components/sections/About';
import Contact from './components/sections/Contact';
import Departments from './components/sections/Departments';
import Gallery from './components/sections/Gallery';
import Home from './components/sections/Home';
import OurExperts from './components/sections/OurExperts';
import Testimonials from './components/sections/Testimonials';
import Header from './components/Header';
import NavProvider from './components/context/NavContext';
import Footer from './components/footer/Footer';


function App() {
  return (
   <> <NavProvider>

<Header/>
<Home/>
<About/>
<Departments/>
<OurExperts/>
<Gallery/>
<Testimonials/>
<Contact/>
<Footer/>
</NavProvider>

   </>
  );
}

export default App;
