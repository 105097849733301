import React from 'react'
import '../CSS/Gallery.css';
import { useNav } from "../Hooks/useNav";
import GalleryData from './GalleryData'

const Gallery = () => {
  const galleryRef = useNav("Gallery")
  return (
    <>
    <section ref={galleryRef} id='gallerySection' className='secondary_section'>
    <div className='gallery_container'>
    <div className='galleryinner_container'><h1>Gallery</h1></div>
    </div>
    <div><GalleryData/></div>
    <div className='space' >End of Gallery</div>
    </section>
    </>
  )
}

export default Gallery