import React from 'react'
import { useNav } from '../Hooks/useNav'
import '../CSS/Testimonials.css'

const Testimonials = () => {
  const testimonialstRef = useNav("Testimonials")
  return (  
  <>
  <section ref={testimonialstRef} id='testimonialsSection'>
  <div className='testimonial_container'>
   <div className='testimonialinner_container'>
   <h1>Testimonials</h1>
    <div className='outer__Container container__flex'>
    <div className='testimonialcard__container'>COMING </div>
    <div className='testimonialcard__container'>........</div>
    <div className='testimonialcard__container'>SOON</div>
    </div>
    </div>
   </div>
   </section>
   </>
   
  )
}

export default Testimonials