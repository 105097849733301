import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleChevronLeft, 
  faCircleChevronRight, 
  faCircleXmark
} from '@fortawesome/free-solid-svg-icons'

import '../CSS/Gallery.css'

const galleryImages = [
  { 
      id: 1,
      name: "Natraj 1",
      url: "/gallery/p1.jpeg",
    },
    {
      id: 2,
      name: "Natraj 2",
      url: "/gallery/p2.jpeg",
    },
    {
      id: 3,
      name: "Natraj 3",
      url: "/gallery/p3.jpeg",
    },
    {
      id: 4,
      name: "Natraj 4",
      url: "/gallery/p4.jpeg",
    },
    {
     id:5,
      name: "Natraj 5",
    url: "/gallery/p5.jpeg",
    },
    { 
      id: 6,
      name: "Natraj 6",
      url: "/gallery/p6.jpeg",
    },
    {
      id: 7,
      name: "Natraj 7",
      url: "/gallery/p7.jpeg",
    },
    {
      id: 8,
      name: "Natraj 8",
      url: "/gallery/p8.jpeg",
    },
    {
      id: 9,
      name: "Natraj 9",
      url: "/gallery/p9.jpeg",
    },
    {
     id:10,
      name: "Natraj 10",
    url: "/gallery/p10.jpeg",
    },
    { 
      id: 11,
      name: "Natraj 11",
      url: "/gallery/p11.jpeg",
    },
    {
      id: 12,
      name: "Natraj 12",
      url: "/gallery/p12.jpeg",
    },
    {
      id: 13,
      name: "Natraj 13",
      url: "/gallery/p13.jpeg",
    },
    {
      id: 14,
      name: "Natraj 14",
      url: "/gallery/p14.jpeg",
    },
    {
     id:15,
      name: "Natraj 15",
    url: "/gallery/p15.jpeg",
    },
    { 
      id: 16,
      name: "Natraj 16",
      url: "/gallery/p16.jpeg",
    },
    {
      id: 17,
      name: "Natraj 17",
      url: "/gallery/p17.jpeg",
    },
    {
      id: 18,
      name: "Natraj 18",
      url: "/gallery/p18.jpeg",
    },
    // {
    //   id: 19,
    //   name: "Natraj 19",
    //   url: "/gallery/p19.jpeg",
    // },
    {
     id:20,
      name: "Natraj 20",
    url: "/gallery/p20.jpeg",
    },
    { 
      id: 21,
      name: "Natraj 21",
      url: "/gallery/p21.jpeg",
    },
    {
      id:22,
      name: "Natraj 22",
      url: "/gallery/p22.jpeg",
    },
    {
      id: 23,
      name: "Natraj 23",
      url: "/gallery/p23.jpeg",
    },
    {
      id: 24,
      name: "Natraj 24",
      url: "/gallery/p24.jpeg",
    },
  ]
const GalleryData = () => {

  const [slideNumber, setSlideNumber] = useState(0)
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = (index) => {
    setSlideNumber(index)
    setOpenModal(true)
  }

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0 
    ? setSlideNumber( galleryImages.length -1 ) 
    : setSlideNumber( slideNumber - 1 )
  }

  // Next Image  
  const nextSlide = () => {
    slideNumber + 1 === galleryImages.length 
    ? setSlideNumber(0) 
    : setSlideNumber(slideNumber + 1)
  }

  return (
    <div>

      {openModal && 
        <div className='sliderWrap'>
          <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal}  />
          <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
          <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
          <div className='fullScreenImage'>
            <img src={galleryImages[slideNumber].url} alt='' />
          </div>
        </div>
      }

      {/* <br />
      Current slide number:  {slideNumber}
      <br />
      Total Slides: {galleryImages.length}
      <br /><br /> */}

      <div className='galleryWrap'>
        {
          galleryImages && galleryImages.map((slide, index) => {
            return(
              <div 
                className='single' 
                key={index}
                onClick={ () => handleOpenModal(index) }
              >
                <img src={slide.url} alt={slide.name} />
              </div>
            )
          })
        }
      </div>

    </div>
  )
}

export default GalleryData