import React from 'react'
import { useNav } from "../Hooks/useNav"
import '../CSS/OurExperts.css'
import OurExpertsCardData from '../cards/OurExpertsCardData'

const OurExperts = () => {
  
  const expertsRef = useNav("OurExperts")
  return (
  <>
  <section ref={expertsRef} id='ourexpertsSection'>
   <div className='expert_container'>
   <h1>OurExperts</h1>
    <div className='expertinner_container'>
     
      <div className="Ecard__container">
        <OurExpertsCardData/>
        </div>
    </div>
    </div>
    </section>
  </>
   
  )
}

export default OurExperts