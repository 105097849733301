import React from 'react'
import '../styles/OurExpertCard.css';


const OurExpertsCard = ({DocImg,DocName,designation}) => {
  return (
    
    <div className='Ecard_container'>
    <div className="image__container"><img src={DocImg} alt='Doctorpic' className='imgcontent'></img></div>
    <h3>{DocName}</h3>
    <h4>{designation}</h4>
    </div>
  )
}

export default OurExpertsCard