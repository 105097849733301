import React from 'react'
import one from '../Images/Experts/1.jpeg';
import two from '../Images/Experts/2.jpeg';
import three from '../Images/Experts/3.png';
import four from '../Images/Experts/4.png';
import OurExpertsCard from './OurExpertsCard';
import '../styles/OurExpertsCardData.css'
const expertsData=[
    {
        id:1,
        image:one,
        docname:"Dr. D.S.Mehar",
        desg:"M.B.B.S"

        
    },
    {
        id:2,
        image:two,
        docname:"Dr. C.S.Mehar",
        desg:"M.D"

    },
    {
        id:3,
        image:three,
        docname:"Doctor",
        desg:"M.B.B.S, Gynologist"

    },
    {
      id:4,
      image:four,
      docname:"Doctor",
      desg:"M.B.B.S, M.S.  surgeon"

  },
]
const OurExpertsCardData = () => {
  return (
    <div className="expert__Container">
      <div className="data__content">
        {
        expertsData.map((Edata) =>(
        <div key={Edata.id}>
          <OurExpertsCard DocImg={Edata.image} DocName={Edata.docname} designation={Edata.desg}/>

        </div>
        ))
        }
      </div>
    </div>

  )
}

export default OurExpertsCardData