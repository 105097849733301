import { v4 as uuidv4 } from "uuid";
const BannerData=[
        {
          id: uuidv4(),
          // title: "Your Care Starts Here",
          // subTitle:  "We ensures the best health care as well as clinical service",
    
          
        },
        {
          id: uuidv4(),
          // title: "Your Care Starts Here",
          // subTitle:  "We ensures the best health care as well as clinical service"
        },
        {
          id: uuidv4(),
          // title: "Your Care Starts Here",
          // subTitle:  "We ensures the best health care as well as clinical service"
        },
        {
          id: uuidv4(),
          // title: "Your Care Starts Here",
          // subTitle:  "We ensures the best health care as well as clinical service"
        },
        {
          id: uuidv4(),
          // title: "Your Care Starts Here",
          // subTitle:  "We ensures the best health care as well as clinical service"
        },
        {
          id: uuidv4(),
          // title: "Your Care Starts Here",
          // subTitle:  "We ensures the best health care as well as clinical service"
        },
        {
          id: uuidv4(),
          // title: "Your Care Starts Here",
          // subTitle:  "We ensures the best health care as well as clinical service"
        },
        {
          id: uuidv4(),
          // title: "Your Care Starts Here",
          // subTitle:  "We ensures the best health care as well as clinical service"
        },
        {
          id: uuidv4(),
          title: "Your Care Starts Here",
          subTitle:  "We ensures the best health care as well as clinical service"
        }
      ];
      
      export default BannerData;

