import React from 'react'
// import { MdHealthAndSafety } from "react-icons/md"
import DepartmentCard from './DepartmentCard';
import heartbeat1 from '../service_images/heartbeat.gif';
import dentistry from '../service_images/dentistry.gif';
import neurology from '../service_images/neurology.gif';
import urology from '../service_images/urology.gif';
import medicine from '../service_images/medicine.gif';
import ortho from '../service_images/ortho.gif';
import pediatrics from '../service_images/pediatrics.png';
import surgery from '../service_images/surgery.gif';
import radiology from '../service_images/radiology.gif';
import Emergency from '../service_images/Emergency.gif';


const CardData=[
    {
        id: 1,
        Dname: "Cardiology",
        icon:<img src={heartbeat1} alt="service1" height="100px" width="100px"></img>
                // desc: "We ensures the best health care as well as clinical service",
        // icon:<MdHealthAndSafety color='cornflowerblue' size ="3rem"/>
    },
    {
        id: 2,
        Dname: "Orthopedics",
        // desc: "We ensures the best health care as well as clinical service",
        icon:<img src={ortho} alt="service1" height="100px" width="100px"></img>
    },
    {
        id: 3,
        Dname: "Surgery",
        // desc: "We ensures the best health care as well as clinical service",
        icon:<img src={surgery} alt="service1" height="100px" width="100px"></img>
    },
    {
        id: 4,
        Dname: "Dentistry",
        // desc: "We ensures the best health care as well as clinical service",
        icon:<img src={dentistry} alt="service1" height="100px" width="100px"></img>
    },
    {
      id: 5,
      Dname: "Radiology",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<img src={radiology} alt="service1" height="100px" width="100px"></img>
  },
  {
      id: 6,
      Dname: "Urology",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<img src={urology} alt="service1" height="100px" width="100px"></img>
  },
  {
      id: 7,
      Dname: "Medicine",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<img src={medicine} alt="service1" height="100px" width="100px"></img>
  },
  {
      id: 8,
      Dname: "Neurology",
      // desc: "We ensures the best health care as well as clinical service",
      icon:<img src={neurology} alt="service1" height="100px" width="100px"></img>
  },
  {
    id: 9,
    Dname: "Paediatrics",
    // desc: "We ensures the best health care as well as clinical service",
    icon:<img src={pediatrics} alt="service1" height="100px" width="100px"></img>
},
{
  id: 9,
  Dname: "Emergency",
  icon:<img src={Emergency} alt="service1" height="100px" width="100px"></img>
  
  // icon:<img src={heartbeat1} alt="service1" height="100px" width="100px"></img>
},
]

const DepartmentCardData=() =>
{
  return (
    <div className='cards_container'>
    <div className='cards_details'>
      {
    CardData.map((card) =>(
    <div key= {card.id}>
    <DepartmentCard icons={card.icon} Dname={card.Dname} description={card.desc}/>
    </div> 

    )) 
      }
    
    </div>
    </div>
  );
}

export default DepartmentCardData

