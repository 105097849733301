import React from 'react';
import AboutUs from '../Images/about.jpg';
import '../styles/About.css';
import { useNav } from "../Hooks/useNav";

const About = () => {
  
  const aboutRef = useNav("AboutUs")
  return (
    <>
      <section ref={aboutRef} id='aboutusSection'>
        <div className='about__container container-flex'>
        <h1>About Us</h1>
        <div className='inner_content'>
          <div className='text_container'>
            <p><h3>नटराज हॉस्पिटल, बिजोलिया (राजस्थान):</h3><p color="red"><b> नटराज हॉस्पिटल, बिजोलिया में आपका स्वागत है!

                 </b></p>
                  

              </p>
              <br/>
              <p>
              हमारा उद्देश्य है प्रत्येक व्यक्ति को उत्कृष्ट और सुलभ स्वास्थ्य सेवाएं प्रदान करना। हमारे हॉस्पिटल में उच्च गुणवत्ता वाली स्वास्थ्य सुविधाएं, अनुभवी डॉक्टरों और प्रशिक्षित स्टाफ द्वारा दी जाती हैं, जो हर मरीज की सेहत का विशेष ध्यान रखते हैं।, </p>
              <br/>
              <p>
              हमारे पास अत्याधुनिक तकनीकी सुविधाएं, अनुभवी चिकित्सक, और समर्पित चिकित्सा कर्मचारी हैं, जो विभिन्न बीमारियों का सही समय पर निदान और उपचार सुनिश्चित करते हैं। नटराज हॉस्पिटल में हम मानते हैं कि हर जीवन अनमोल है, और इसलिए हम उच्च स्तरीय देखभाल के साथ-साथ मरीजों को स्वस्थ जीवन की दिशा में प्रेरित करते हैं।</p>
              <br/>
              <p><h3>हमारी सेवाएं:</h3></p><br/>
              <p>
             
. विशेषज्ञ डॉक्टरों की टीम <br/>
. प्राथमिक और द्वितीयक चिकित्सा उपचार<br/>
. सर्जरी और ऑपरेशन थियेटर सुविधाएं<br/>
. महिला एवं बाल चिकित्सा सेवाएं<br/>
. डायग्नोस्टिक लैब और इमेजिंग सुविधाएं</p><br/>

<p><h3>हमारा मिशन:</h3>
                  

              </p>
              <br/>
              <p>
              नटराज हॉस्पिटल का मिशन है हर नागरिक को विश्वस्तरीय चिकित्सा सेवाएं उपलब्ध कराना, जिससे न केवल बीमारी का इलाज हो, बल्कि सम्पूर्ण स्वास्थ्य सुधार की दिशा में योगदान दिया जा सके। </p>
              
              
              </div>


              <div className='img_container'>
                <img src={AboutUs} alt='about'></img>
              </div>
        </div>
        </div>
        </section>
    </>
    
  )
}

export default About